import React from "react"

import Layout from "../components/layout"

const TermsConditions = () => {
  return (
    <Layout>
      <main role="main" className="bg-white">
        <div style={{ backgroundColor: "#e7e7e7" }} className="relative">
          <img
            src="/images/terms_conditions.jpeg"
            className="db center"
            style={{ width: "64rem" }}
            alt=""
          />
        </div>

        <div className="mw7 center pa4">
          <h2 className="f2 lato fw4">Termini a condizioni</h2>
          <p className="lato f4">
            I termini e le condizioni del servizio sono attualmente ancora in
            fase di definizione.
          </p>
        </div>
      </main>
    </Layout>
  )
}

export default TermsConditions
